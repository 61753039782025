import { defineStore } from "pinia";
import { useCalculatorApi } from "~/api/useCalculatorApi";

import {
  Congestion,
  FormInputs,
  FormValue,
  InputField,
  MetaInput,
  PairData,
} from "~/types/calculator";
import { useDirectionsStore } from "~/stores/directionsStore";

export const useCalculatorStore = defineStore("calculator", () => {
  const pairData = ref<PairData | null>(null);
  const course = ref<number | null>(null);
  const discount = ref<number | null>(null);
  const congestion = ref<Congestion>();
  const formValues = ref<Record<string, FormValue>>({});
  const email = ref("");
  const recaptcha = ref("");

  const referral = ref<string | null>(null);

  const promocode = ref<string | null>(null);

  const saveRequisites = ref(false);

  const fromValue = ref<number | null>(null);

  const directionsStore = useDirectionsStore();

  const { fetchPair, fetchCourse } = useCalculatorApi();
  const formInputsMeta = ref<Record<string, MetaInput>>({});

  const setPromocode = (value: string) => {
    promocode.value = value;
  };

  const formInputs = computed<FormInputs>(() => {
    if (!pairData.value) return { from: [], to: [] };
    return {
      from: getVisibleInputs(pairData.value.structure.from.input, "from"),
      to: getVisibleInputs(pairData.value.structure.to.input, "to"),
    };
  });

  function updateFormValues() {
    const inputDirections = Object.keys(formInputs.value);
    inputDirections.forEach((direction) => {
      const inputs = formInputs.value[direction];
      if (!formValues.value[direction]) {
        formValues.value[direction] = {};
      }
      inputs.forEach((input: InputField) => {
        if (!formValues.value[direction][input.name]) {
          formValues.value[direction][input.name] = "";
        }
      });
    });
  }

  const filteredFormValues = computed<Record<string, FormValue>>(() => {
    const inputDirections = Object.keys(formInputs.value);
    const filteredValues: Record<string, FormValue> = {};
    inputDirections.forEach((direction) => {
      const inputs = formInputs.value[direction];
      const values = formValues.value[direction];

      if (Array.isArray(inputs)) {
        const filteredInputs = inputs.filter((input) =>
          Object.prototype.hasOwnProperty.call(values, input.name),
        );
        filteredValues[direction] = filteredInputs.reduce((obj, input) => {
          obj[input.name] = values[input.name];
          return obj;
        }, {} as FormValue);
      } else {
        filteredValues[direction] = values;
      }
    });
    return filteredValues;
  });

  const fromOptions = computed(() => {
    if (!pairData.value) return [];
    if (
      pairData.value.structure.from.currency.length >
      pairData.value.structure.from.network.length
    ) {
      return pairData.value.structure.from.currency;
    }
    return pairData.value.structure.from.network;
  });

  const toOptions = computed(() => {
    if (!pairData.value) return [];
    if (
      pairData.value.structure.to.currency.length >
      pairData.value.structure.to.network.length
    ) {
      return pairData.value.structure.to.currency;
    }
    return pairData.value.structure.to.network;
  });

  const fromSavedRequisites = computed(() => {
    if (!pairData.value) return [];

    return pairData.value.structure.from.savedRequisites;
  });

  const toSavedRequisites = computed(() => {
    if (!pairData.value) return [];

    return pairData.value.structure.to.savedRequisites;
  });

  async function getCourse() {
    const { fromCurrencyId, toCurrencyId } = directionsStore;
    if (!fromCurrencyId || !toCurrencyId) return;
    try {
      const response = await fetchCourse(fromCurrencyId, toCurrencyId);
      course.value = response.course;
    } catch (error) {
      console.log(error);
    }
  }

  function updateFormInputsMeta() {
    if (!pairData.value) return;
    const { from, to } = pairData.value;

    formInputsMeta.value = {
      from: {
        min: from.min ? from.min : to.min / course.value!,
        max: from.max,
        round: from.roundCalculator,
      },
      to: {
        min: to.min ? to.min : from.min * course.value!,
        max: to.max,
        round: to.roundCalculator,
      },
    };
    fromValue.value = formInputsMeta.value.from.min;
  }

  async function getData() {
    const { fromCurrencyId, toCurrencyId } = directionsStore;
    if (!fromCurrencyId || !toCurrencyId) return;
    try {
      const response = await fetchPair(fromCurrencyId, toCurrencyId);
      course.value = response.course;
      pairData.value = response;
      discount.value = response.bonus;
      congestion.value = response.structure.congestion;
      updateFormValues();
      updateFormInputsMeta();
    } catch (error) {
      console.log(error);
    }
  }
  const toValue = computed(() => {
    if (fromValue.value === null || !course.value) return null;
    return roundNumber(
      fromValue.value * course.value,
      formInputsMeta.value.to.round,
    );
  });

  function handleInput(direction: "from" | "to", value: number) {
    if (direction === "from") {
      fromValue.value = value;
    } else if (direction === "to") {
      fromValue.value = value / course.value!;
    }
  }

  function handleChange(direction: "from" | "to", value: number) {
    if (direction === "to") {
      handleChange("from", value / course.value!);
      return;
    }
    // check min max values = value;
    if (value < formInputsMeta.value.from.min) {
      fromValue.value = formInputsMeta.value.from.min;
    } else if (value > formInputsMeta.value.from.max) {
      fromValue.value = formInputsMeta.value.from.max;
    }
  }

  return {
    getData,
    getCourse,
    email,
    recaptcha,
    pairData,
    formInputs,
    course,
    formValues,
    filteredFormValues,
    fromOptions,
    toOptions,
    fromValue,
    toValue,
    formInputsMeta,
    handleInput,
    handleChange,
    referral,
    discount,
    congestion,
    saveRequisites,
    fromSavedRequisites,
    toSavedRequisites,
    promocode,
    setPromocode,
  };
});
