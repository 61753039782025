import { Direction, DirectionWithId, InputField } from "~/types/calculator";

export function getDirectionsList(directions: Direction[], ids: number[]) {
  const result: DirectionWithId[] = [];
  directions.forEach((direction: Direction) => {
    ids.every((id: number) => {
      if (direction.ids.includes(id)) {
        result.push({ ...direction, id });
        return false;
      }
      return true;
    });
  });
  return result;
}

export function getDirectionItem(
  directions: Direction[],
  id: number,
): DirectionWithId | null {
  const direction = directions.find((dir: Direction) => dir.ids.includes(id));
  if (direction) {
    return {
      id,
      ...direction,
    };
  }
  return null;
}

export function getVisibleInputs(
  inputs: InputField[],
  direction: "from" | "to",
) {
  const dir = direction === "from" ? "send" : "receive";
  if (!inputs) {
    return [];
  }
  return inputs.filter((input: InputField) => input[dir].visible);
}
