import { defineStore } from "pinia";
import { Direction, Reserve } from "~/types/calculator";
import { useCalculatorApi } from "~/api/useCalculatorApi";
import { useCalculatorStore } from "~/stores/calculatorStore";
import { getDirectionItem } from "~/utils/calculator";

export const useDirectionsStore = defineStore("directions", () => {
  const directions = ref<Direction[] | null>(null);
  const reserves = ref<Reserve[] | null>(null);
  const fromCurrenciesIds = ref<number[]>([]);
  const fromAllCurrenciesIds = ref<number[]>([]);
  const toCurrenciesIds = ref<number[]>([]);

  const fromCurrencyId = ref<number | null>(null);
  const toCurrencyId = ref<number | null>(null);

  const calculatorStore = useCalculatorStore();

  const {
    fetchDirections,
    fetchReserves,
    fetchFromCurrenciesIds,
    fetchToCurrenciesIds,
    fetchFromAllCurrenciesIds,
  } = useCalculatorApi();

  const getDirections = async () => {
    const { data } = await fetchDirections();
    if (data.value) {
      directions.value = data.value;
    }
  };

  const getReserves = async () => {
    const { data } = await fetchReserves();
    if (data.value) {
      reserves.value = data.value;
    }
  };

  const setFromCurrencyId = (id: number) => {
    fromCurrencyId.value = id;
  };

  const setToCurrencyId = (id: number) => {
    toCurrencyId.value = id;
  };

  const getFromCurrenciesIds = async () => {
    try {
      const response = await fetchFromCurrenciesIds();
      if (response.length > 0) {
        fromCurrenciesIds.value = response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getToCurrenciesIds = async () => {
    try {
      if (fromCurrencyId.value) {
        const response = await fetchToCurrenciesIds(fromCurrencyId.value);
        if (response.length > 0) {
          toCurrenciesIds.value = response;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFromAllCurrenciesIds = async () => {
    try {
      if (toCurrencyId.value) {
        const response = await fetchFromAllCurrenciesIds(toCurrencyId.value);
        if (response.length > 0) {
          fromAllCurrenciesIds.value = response;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async (fromId: number = 0, toId: number = 0) => {
    await getFromCurrenciesIds();
    if (fromCurrenciesIds.value.length > 0 && !fromCurrencyId.value) {
      setFromCurrencyId(fromId || fromCurrenciesIds.value[0]);
    }
    await getToCurrenciesIds();
    if (toCurrenciesIds.value.length > 0 && !toCurrencyId.value) {
      setToCurrencyId(toId || toCurrenciesIds.value[0]);
    }
    // fetch data
    await calculatorStore.getData();
  };

  const onCurrencyChange = async (direction: string, id: number) => {
    if (direction === "from") {
      setFromCurrencyId(id);
      await getToCurrenciesIds();
      if (
        toCurrencyId.value &&
        !toCurrenciesIds.value.includes(toCurrencyId.value)
      ) {
        setToCurrencyId(toCurrenciesIds.value[0]);
      }
      // fetch data
      await calculatorStore.getData();
    } else {
      setToCurrencyId(id);
      await getFromAllCurrenciesIds();
      if (
        fromCurrencyId.value &&
        !fromAllCurrenciesIds.value.includes(fromCurrencyId.value)
      ) {
        setFromCurrencyId(fromAllCurrenciesIds.value[0]);
      }
      // fetch data
      await calculatorStore.getData();
    }
  };

  const fromDirections = computed(() => {
    if (!directions.value) {
      return [];
    }
    return getDirectionsList(directions.value, fromCurrenciesIds.value);
  });

  const toDirections = computed(() => {
    if (!directions.value) {
      return [];
    }
    return getDirectionsList(directions.value, fromCurrenciesIds.value);
  });

  const fromDirection = computed(() => {
    if (!directions.value || !fromCurrencyId.value) {
      return null;
    }
    return getDirectionItem(directions.value, fromCurrencyId.value);
  });

  const toDirection = computed(() => {
    if (!directions.value || !toCurrencyId.value) {
      return null;
    }
    return getDirectionItem(directions.value, toCurrencyId.value);
  });

  const getReserveById = (id: number) => {
    if (!reserves.value) {
      return null;
    }
    return reserves.value.find((reserve) => reserve.id === id);
  };

  const onCurrenciesChange = async (fromId: number, toId: number) => {
    fromCurrencyId.value = fromId;

    await getToCurrenciesIds();

    await setToCurrencyId(toId);
    await calculatorStore.getData();
  };

  return {
    fromCurrencyId,
    toCurrencyId,
    getDirections,
    getReserveById,
    getReserves,
    init,
    onCurrencyChange,
    setFromCurrencyId,
    setToCurrencyId,
    directions,
    fromDirections,
    toDirections,
    fromDirection,
    toDirection,
    onCurrenciesChange,
  };
});
