<template>
  <transition
    name="form-fade"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <div v-if="visible" :class="$style.BaseModal">
      <div :class="$style.close">
        <SvgoIconClose
          name="cross"
          :class="$style.icon"
          @click="onCloseClick"
        />
      </div>
      <div :class="$style.content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script setup>
defineProps({
  login: Boolean,
  visible: Boolean,
});
const emit = defineEmits(["close", "after-leave", "after-enter"]);

function onCloseClick() {
  emit("close");
}

onMounted(() => {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      emit("close");
    }
  });
});
</script>

<style lang="scss" module>
.BaseModal {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 70rem;
  padding: 5rem;
  width: 95%;
  border: 2px solid $body-color;
  background: $bg-color;
  transform: translate(-50%, -50%);

  @include respond-to(xs) {
    padding: 50px 17px;
    max-width: none;
  }

  &:global(.modal-appear-enter-active) {
    transition: transform 0.4s;
  }

  &:global(.modal-appear-leave-active) {
    transition: transform 0.2s;
  }

  &:global(.modal-appear-enter-from),
  &:global(.modal-appear-leave-to) {
    transform: translateX(110%);
  }

  &:global(._to-left) {
    right: auto;
    left: 0;

    &:global(.modal-appear-enter),
    &:global(.modal-appear-leave-to) {
      transform: translateX(-110%);
    }
  }
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 1.8rem;

  @include respond-to(xs) {
    top: 17px;
    right: 17px;
    width: 15px;
  }

  .icon {
    width: 100%;
    height: 100%;
    transition: color $default-transition;

    &:hover {
      cursor: pointer;
      color: $brand-green;
    }
  }
}
</style>
