<template>
  <div :class="$style.Error">
    <div :class="$style.content">
      <div :class="$style.icon">
        <img src="/images/error/duck.svg" alt="duck" />
        <div :class="$style.box">Кажется, тут ничего нет...</div>
      </div>
      <p :class="$style.code">{{ statusCode }}</p>
    </div>
    <h1 :class="$style.title">{{ title }}</h1>
    <VButton :class="$style.btn" @click="onClick"> На главную</VButton>
  </div>
</template>

<script setup lang="ts">
import VButton from "~/components/ui/Button/VButton.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  statusCode: {
    type: Number,
    required: true,
  },
});

const title = computed(() => {
  if (props.statusCode === 404) {
    return "Страница удалена или изменена";
  }
  return "Ошибка";
});

function onClick() {
  clearError({ redirect: "/" });
}
</script>

<style lang="scss" module>
.Error {
  text-align: center;
}

.content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1.3rem;
  padding: 3rem;
}

.icon {
  position: relative;

  svg {
    width: 20rem;
    height: auto;

    @include respond-to(xs) {
      width: 158px;
    }
  }
}

.box {
  position: absolute;
  top: 0;
  right: -24rem;
  font-family: $font-family-secondary;
  font-size: 1.4rem;
  letter-spacing: -0.28px;
  min-height: 5rem;
  width: 100%;
  max-width: 24rem;
  padding: 2.8rem 1rem;
  margin: 0 auto;
  background: $body-color;

  @include respond-to(xs) {
    font-size: 10px;
    letter-spacing: -0.22px;
    right: -170px;
    padding: 20px 10px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 2px;
    z-index: -1;
    min-height: calc(5rem - 4px);
    background: $bg-color;
  }
}

.box,
.box::before {
  clip-path: polygon(
    8% 100%,
    0% 100%,
    0% 0%,
    47% 0%,
    53% 9%,
    79% 9%,
    85% 0%,
    100% 0%,
    100% 75%,
    85% 88%,
    8% 88%
  );
}

.code {
  font-family: $font-family-secondary;
  font-size: 7.4rem;
  letter-spacing: -1.479px;

  @include respond-to(xs) {
    font-size: 55px;
    letter-spacing: -0.22px;
  }
}

.title {
  font-family: $font-family-secondary;
  font-size: 2rem;
  color: $body-color;
  letter-spacing: -0.4px;
  text-align: center;
  margin-bottom: 6rem;

  @include respond-to(xs) {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.24px;
  }
}

.btn {
  width: fit-content;
  margin: 0 auto;
}
</style>
