import { defu } from "defu";
import { NitroFetchOptions } from "nitropack";
import type { AsyncDataOptions, UseFetchOptions } from "nuxt/app";
import { FetchResponse } from "ofetch";
import { useAuthApi } from "~/api/useAuthApi";
import { useUser } from "~/composables/useUser";

export function useApiFetch<T>(
  url: string,
  options: NitroFetchOptions<any> = {},
) {
  const config = useRuntimeConfig();
  const defaults = {
    baseURL: config.public.baseUrl,
  };
  const params = defu(options, defaults);

  return $fetch(url, params) as Promise<T>;
}

export function useBasedFetch<T>(
  url: string,
  options: UseFetchOptions<T> = {},
) {
  const config = useRuntimeConfig();
  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.baseUrl,
  };
  const params = defu(options, defaults);

  return useFetch(url, params);
}

export function useAuthApiFetch<T>(
  url: string,
  opts: NitroFetchOptions<any> = {},
) {
  const config = useRuntimeConfig();
  const defaults: NitroFetchOptions<any> = {
    baseURL: config.public.baseUrl ?? "https://kotleta.gg/",
    onRequest({ options }): Promise<void> | void {
      const { accessToken } = useUser();
      if (accessToken.value) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${accessToken.value}`,
        };
      }
    },
    async onResponse(context): Promise<void> {
      if (
        context.response.status === 401 &&
        context.response._data.code === "token_not_valid"
      ) {
        const { data: access } = await useAuthApi().refresh();
        useUser().accessToken.value = access.value as string;
        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${access.value}`,
        };
        context.response = (await $fetch.raw(
          context.request,
          context.options as NitroFetchOptions<any>,
        )) as FetchResponse<any>;
      }
    },
  };

  const params = defu(opts, defaults);
  return $fetch(url, params) as Promise<T>;
}

export function useAuthBasedFetch<T>(
  url: string,
  opts: AsyncDataOptions<any> = {},
) {
  const config = useRuntimeConfig();
  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.baseUrl ?? "https://kotleta.gg/",
  };
  const params = defu(opts, defaults);

  return useAsyncData(url, () => useAuthApiFetch(url), params);
}
