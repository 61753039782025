<template>
  <LayoutModalsBaseModal
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.MessageModal">
      <h1 class="common-title">{{ data?.title }}</h1>
      <p class="p" v-html="data?.message" />
      <UiButtonVButton v-if="data.recover" @click="onClickLogin">{{
        data.btnText ? data.btnText : "Хорошо"
      }}</UiButtonVButton>
      <UiButtonVButton v-else @click="emit('close')">{{
        data.btnText ? data.btnText : "Хорошо"
      }}</UiButtonVButton>
    </div>
  </LayoutModalsBaseModal>
</template>

<script setup lang="ts">
type Props = {
  visible: boolean;
  data: {
    title?: string;
    message?: string;
    btnText?: string;
    recover?: boolean;
  };
};
defineProps<Props>();

const emit = defineEmits(["close", "after-enter", "after-leave"]);

const router = useRouter();

function onClickLogin() {
  router.push("/login");
}
</script>

<style lang="scss" module>
.MessageModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;

  h1 {
    text-align: center;
  }

  p {
    color: $base-500;
    text-align: center;
  }
}
</style>
