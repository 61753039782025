<template>
  <div :class="$style.Error500">
    <NuxtLayout name="tech">
      <div :class="$style.wrapper">
        <h1 class="h2 center">Ведутся технические работы</h1>
        <p class="p-big">Попробуйте зайти на сайт позднее</p>
        <SvgoIconDinosaur />
      </div>
    </NuxtLayout>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 3rem;

    @include respond-to(sm) {
      margin-bottom: 10px;
      margin-top: 60px;
    }
  }

  p {
    margin-bottom: 6rem;
    color: $base-500;

    @include respond-to(sm) {
      margin-bottom: unset;
    }
  }

  svg {
    color: $brand-green;
    width: 36rem;
    height: auto;

    @include respond-to(sm) {
      order: -1;
      width: 205px;
    }
  }
}
</style>
