import { useApiFetch, useAuthApiFetch, useAuthBasedFetch } from "~/api/useApi";
import type { ReferralBalance, WithdrawalList } from "~/types/referral";

const URLS = {
  fetchClaimsUrl: (page: number, size: number) =>
    `/api/v1/users/me/claims/?page=${page}&size=${size}`,
  changePasswordUrl: "/api/v1/users/me/password/",
  reactivateAccountUrl: "/api/v1/users/reactivate/",
  activateAccountUrl: (code: string) => `/api/v1/users/activate/${code}/`,
  fetchDwUrl: (page: number, size: number) =>
    `/api/v1/users/me/refferal/dw/?page=${page}&size=${size}`,
  fetchReferralBalanceUrl: "/api/v1/users/me/refferal/",
  fetchReferralWithdrawsUrl: "/api/v1/users/me/refferal/withdrawal/",
  signupAccountUrl: "/api/v1/users/signup/",
  restoreAccountUrl: "/api/v1/users/restore/",
  resetAccountUrl: (code: string) => `/api/v1/users/restore/${code}/`,
  fetchBestPairUrl: `/api/v1/users/pairs/best`,
  fetchRequisitesUrl: (page: number, size: number, type: string) =>
    `/api/v1/users/me/requisites/?type=${type}&page=${page}&size=${size}`,
  fetchPaysystemsUrl: (type: string) =>
    `/api/v1/users/me/requisites/inputs/?type=${type}`,
  changeRequisiteUrl: (id: number) => `/api/v1/users/me/requisites/${id}/`,
  addRequisiteUrl: "api/v1/users/me/requisites/",
  deleteRequisiteUrl: (id: number) => `/api/v1/users/me/requisites/${id}/`,
  fetchStatisticsUrl: "/api/v1/users/me/statistics/",
  fetchPromocodesUrl: "/api/v1/users/me/promocodes/",
  repeatClaimUrl: (uuid: string) => `/api/v1/users/me/claims/${uuid}/`,
};

export const useAccountApi = () => {
  const fetchClaims = (page: number, size: number) => {
    return useAuthBasedFetch(URLS.fetchClaimsUrl(page, size));
  };

  const changePassword = (data: any) => {
    return useAuthApiFetch(URLS.changePasswordUrl, {
      method: "POST",
      body: data,
    });
  };

  const reactivateAccount = () => {
    return useAuthApiFetch(URLS.reactivateAccountUrl, {
      method: "POST",
    });
  };

  const activateAccount = (code: string) => {
    return useAuthApiFetch(URLS.activateAccountUrl(code), {
      method: "POST",
    });
  };

  const fetchReferralBalance = () => {
    return useAuthBasedFetch<ReferralBalance>(URLS.fetchReferralBalanceUrl);
  };

  const fetchDw = (page: number, size: number) => {
    return useAuthBasedFetch(URLS.fetchDwUrl(page, size));
  };

  const fetchReferralWithdraws = () => {
    return useAuthBasedFetch<WithdrawalList>(URLS.fetchReferralWithdrawsUrl);
  };

  const fetchReferralWithdraw = (data: any) => {
    return useAuthApiFetch(URLS.fetchReferralWithdrawsUrl, {
      method: "POST",
      body: data,
    });
  };

  const signupAccount = (data: any) => {
    return useApiFetch(URLS.signupAccountUrl, { method: "POST", body: data });
  };

  const restoreAccount = (data: any) => {
    return useApiFetch(URLS.restoreAccountUrl, { method: "POST", body: data });
  };

  const resetAccount = (code: string, data: any) => {
    return useAuthApiFetch(URLS.resetAccountUrl(code), {
      method: "POST",
      body: data,
    });
  };

  const fetchBestPair = () => {
    return useAuthBasedFetch(URLS.fetchBestPairUrl);
  };

  const fetchRequisites = (page: number, size: number, type: string) => {
    return useAuthBasedFetch(URLS.fetchRequisitesUrl(page, size, type));
  };

  const fetchPaysystems = (type: string) => {
    return useAuthBasedFetch(URLS.fetchPaysystemsUrl(type));
  };

  const addRequisite = (data: any) => {
    return useAuthApiFetch(URLS.addRequisiteUrl, {
      method: "POST",
      body: data,
    });
  };

  const changeRequisite = (data: any, id: number) => {
    return useAuthApiFetch(URLS.changeRequisiteUrl(id), {
      method: "PATCH",
      body: data,
    });
  };

  const deleteRequisite = (data: any, id: number) => {
    return useAuthApiFetch(URLS.deleteRequisiteUrl(id), {
      method: "DELETE",
      body: data,
    });
  };

  interface TotalMoney {
    value: number;
    currency: string;
  }

  interface Exchanges {
    count: number;
    totalMoney: TotalMoney;
  }

  interface StatisticsResponse {
    exchanges: Exchanges;
    countOfSavedRequisites: number;
    discount: number;
  }

  const fetchStatistics = () => {
    return useAuthBasedFetch<StatisticsResponse>(URLS.fetchStatisticsUrl);
  };

  const repeatClaim = (uuid: string) => {
    return useAuthApiFetch(URLS.repeatClaimUrl(uuid), {
      method: "POST",
    });
  };

  const fetchPromocodes = () => {
    return useAuthBasedFetch(URLS.fetchPromocodesUrl);
  };

  return {
    fetchClaims,
    changePassword,
    reactivateAccount,
    fetchDw,
    fetchReferralBalance,
    fetchReferralWithdraws,
    fetchReferralWithdraw,
    signupAccount,
    restoreAccount,
    resetAccount,
    fetchBestPair,
    fetchRequisites,
    fetchPaysystems,
    addRequisite,
    changeRequisite,
    deleteRequisite,
    fetchStatistics,
    repeatClaim,
    fetchPromocodes,
    activateAccount,
  };
};
