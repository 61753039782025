import { useCalculator } from "~/composables/useCalculator";
import { useSettings } from "~/composables/useSetiings";

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.payload.error) {
    return {};
  }

  await useCalculator().getDirections();
  await useCalculator().getReserves();
  await useSettings().getSettings();

  if (useRequestHeaders()?.cookie?.includes("refreshToken")) {
    await useUser().getToken();
    await useUser().getUser();
  }

  return {};
});
