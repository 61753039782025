import { default as applicationseTsOxpWKkpMeta } from "/workdir/pages/account/applications.vue?macro=true";
import { default as dashboardhSzeFV929BMeta } from "/workdir/pages/account/dashboard.vue?macro=true";
import { default as promocodesUBo8JzP5NSMeta } from "/workdir/pages/account/promocodes.vue?macro=true";
import { default as indexOWAEsL0L5fMeta } from "/workdir/pages/account/referral/index.vue?macro=true";
import { default as safety4Ssk030zPPMeta } from "/workdir/pages/account/safety.vue?macro=true";
import { default as apixFVkH8tFpSMeta } from "/workdir/pages/account/shop/api.vue?macro=true";
import { default as historymdFo7pvosjMeta } from "/workdir/pages/account/shop/history.vue?macro=true";
import { default as link_45generationVSAFqUyxarMeta } from "/workdir/pages/account/shop/link-generation.vue?macro=true";
import { default as statisticsaGCQUTsP7WMeta } from "/workdir/pages/account/shop/statistics.vue?macro=true";
import { default as usageS5BlhUWsqWMeta } from "/workdir/pages/account/shop/usage.vue?macro=true";
import { default as withdrawalWZ3frEVPQBMeta } from "/workdir/pages/account/shop/withdrawal.vue?macro=true";
import { default as shopn8Thp0XF1IMeta } from "/workdir/pages/account/shop.vue?macro=true";
import { default as walletsF2C2EIHGiuMeta } from "/workdir/pages/account/wallets.vue?macro=true";
import { default as accountQy3Qfs0j0GMeta } from "/workdir/pages/account.vue?macro=true";
import { default as indexAj1kXQ7s2aMeta } from "/workdir/pages/aml/index.vue?macro=true";
import { default as indexL2wOIS17NHMeta } from "/workdir/pages/claim/[uuid]/index.vue?macro=true";
import { default as failXdaHQUDRb0Meta } from "/workdir/pages/claim/fail.vue?macro=true";
import { default as indexIPJFi2Je1FMeta } from "/workdir/pages/claim/index.vue?macro=true";
import { default as successLtUxxRtTjZMeta } from "/workdir/pages/claim/success.vue?macro=true";
import { default as indexySkdaZZjB0Meta } from "/workdir/pages/contacts/index.vue?macro=true";
import { default as indexMKwREhkAPMMeta } from "/workdir/pages/contest/index.vue?macro=true";
import { default as indexNrjrPDWjinMeta } from "/workdir/pages/discounts/index.vue?macro=true";
import { default as indexYqXC3W8KKRMeta } from "/workdir/pages/faq/index.vue?macro=true";
import { default as indexgrd9QoUdNTMeta } from "/workdir/pages/forget/index.vue?macro=true";
import { default as indexJ1sPgYGdnvMeta } from "/workdir/pages/index.vue?macro=true";
import { default as indexdt8tCTppMnMeta } from "/workdir/pages/login/index.vue?macro=true";
import { default as indexNAcF2OimVeMeta } from "/workdir/pages/monitoring/index.vue?macro=true";
import { default as _91slug_93YwoilbelrwMeta } from "/workdir/pages/news/[slug].vue?macro=true";
import { default as index2Y6faI97hTMeta } from "/workdir/pages/news/index.vue?macro=true";
import { default as indexo45LdNCFk2Meta } from "/workdir/pages/promocode/index.vue?macro=true";
import { default as indexZdhdgNeEP1Meta } from "/workdir/pages/recover/index.vue?macro=true";
import { default as indexRkfcqitLlkMeta } from "/workdir/pages/register/index.vue?macro=true";
import { default as indexwEYHUqa81RMeta } from "/workdir/pages/reserves/index.vue?macro=true";
import { default as indexk3qcO1cw9bMeta } from "/workdir/pages/rules/index.vue?macro=true";
import { default as indexH6U53uCxdBMeta } from "/workdir/pages/shops/index.vue?macro=true";
import { default as indexPDB9tVlXe9Meta } from "/workdir/pages/users/index.vue?macro=true";
import { default as _91uuid_93FBaU04Y9qoMeta } from "/workdir/pages/verify/[uuid].vue?macro=true";
export default [
  {
    name: accountQy3Qfs0j0GMeta?.name ?? "account",
    path: accountQy3Qfs0j0GMeta?.path ?? "/account",
    children: [
  {
    name: applicationseTsOxpWKkpMeta?.name ?? "account-applications",
    path: applicationseTsOxpWKkpMeta?.path ?? "applications",
    meta: applicationseTsOxpWKkpMeta || {},
    alias: applicationseTsOxpWKkpMeta?.alias || [],
    redirect: applicationseTsOxpWKkpMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/applications.vue").then(m => m.default || m)
  },
  {
    name: dashboardhSzeFV929BMeta?.name ?? "account-dashboard",
    path: dashboardhSzeFV929BMeta?.path ?? "dashboard",
    meta: dashboardhSzeFV929BMeta || {},
    alias: dashboardhSzeFV929BMeta?.alias || [],
    redirect: dashboardhSzeFV929BMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: promocodesUBo8JzP5NSMeta?.name ?? "account-promocodes",
    path: promocodesUBo8JzP5NSMeta?.path ?? "promocodes",
    meta: promocodesUBo8JzP5NSMeta || {},
    alias: promocodesUBo8JzP5NSMeta?.alias || [],
    redirect: promocodesUBo8JzP5NSMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/promocodes.vue").then(m => m.default || m)
  },
  {
    name: indexOWAEsL0L5fMeta?.name ?? "account-referral",
    path: indexOWAEsL0L5fMeta?.path ?? "referral",
    meta: indexOWAEsL0L5fMeta || {},
    alias: indexOWAEsL0L5fMeta?.alias || [],
    redirect: indexOWAEsL0L5fMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/referral/index.vue").then(m => m.default || m)
  },
  {
    name: safety4Ssk030zPPMeta?.name ?? "account-safety",
    path: safety4Ssk030zPPMeta?.path ?? "safety",
    meta: safety4Ssk030zPPMeta || {},
    alias: safety4Ssk030zPPMeta?.alias || [],
    redirect: safety4Ssk030zPPMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/safety.vue").then(m => m.default || m)
  },
  {
    name: shopn8Thp0XF1IMeta?.name ?? "account-shop",
    path: shopn8Thp0XF1IMeta?.path ?? "shop",
    children: [
  {
    name: apixFVkH8tFpSMeta?.name ?? "account-shop-api",
    path: apixFVkH8tFpSMeta?.path ?? "api",
    meta: apixFVkH8tFpSMeta || {},
    alias: apixFVkH8tFpSMeta?.alias || [],
    redirect: apixFVkH8tFpSMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/shop/api.vue").then(m => m.default || m)
  },
  {
    name: historymdFo7pvosjMeta?.name ?? "account-shop-history",
    path: historymdFo7pvosjMeta?.path ?? "history",
    meta: historymdFo7pvosjMeta || {},
    alias: historymdFo7pvosjMeta?.alias || [],
    redirect: historymdFo7pvosjMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/shop/history.vue").then(m => m.default || m)
  },
  {
    name: link_45generationVSAFqUyxarMeta?.name ?? "account-shop-link-generation",
    path: link_45generationVSAFqUyxarMeta?.path ?? "link-generation",
    meta: link_45generationVSAFqUyxarMeta || {},
    alias: link_45generationVSAFqUyxarMeta?.alias || [],
    redirect: link_45generationVSAFqUyxarMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/shop/link-generation.vue").then(m => m.default || m)
  },
  {
    name: statisticsaGCQUTsP7WMeta?.name ?? "account-shop-statistics",
    path: statisticsaGCQUTsP7WMeta?.path ?? "statistics",
    meta: statisticsaGCQUTsP7WMeta || {},
    alias: statisticsaGCQUTsP7WMeta?.alias || [],
    redirect: statisticsaGCQUTsP7WMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/shop/statistics.vue").then(m => m.default || m)
  },
  {
    name: usageS5BlhUWsqWMeta?.name ?? "account-shop-usage",
    path: usageS5BlhUWsqWMeta?.path ?? "usage",
    meta: usageS5BlhUWsqWMeta || {},
    alias: usageS5BlhUWsqWMeta?.alias || [],
    redirect: usageS5BlhUWsqWMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/shop/usage.vue").then(m => m.default || m)
  },
  {
    name: withdrawalWZ3frEVPQBMeta?.name ?? "account-shop-withdrawal",
    path: withdrawalWZ3frEVPQBMeta?.path ?? "withdrawal",
    meta: withdrawalWZ3frEVPQBMeta || {},
    alias: withdrawalWZ3frEVPQBMeta?.alias || [],
    redirect: withdrawalWZ3frEVPQBMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/shop/withdrawal.vue").then(m => m.default || m)
  }
],
    meta: shopn8Thp0XF1IMeta || {},
    alias: shopn8Thp0XF1IMeta?.alias || [],
    redirect: shopn8Thp0XF1IMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/shop.vue").then(m => m.default || m)
  },
  {
    name: walletsF2C2EIHGiuMeta?.name ?? "account-wallets",
    path: walletsF2C2EIHGiuMeta?.path ?? "wallets",
    meta: walletsF2C2EIHGiuMeta || {},
    alias: walletsF2C2EIHGiuMeta?.alias || [],
    redirect: walletsF2C2EIHGiuMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account/wallets.vue").then(m => m.default || m)
  }
],
    meta: accountQy3Qfs0j0GMeta || {},
    alias: accountQy3Qfs0j0GMeta?.alias || [],
    redirect: accountQy3Qfs0j0GMeta?.redirect || undefined,
    component: () => import("/workdir/pages/account.vue").then(m => m.default || m)
  },
  {
    name: indexAj1kXQ7s2aMeta?.name ?? "aml",
    path: indexAj1kXQ7s2aMeta?.path ?? "/aml",
    meta: indexAj1kXQ7s2aMeta || {},
    alias: indexAj1kXQ7s2aMeta?.alias || [],
    redirect: indexAj1kXQ7s2aMeta?.redirect || undefined,
    component: () => import("/workdir/pages/aml/index.vue").then(m => m.default || m)
  },
  {
    name: indexL2wOIS17NHMeta?.name ?? "claim-uuid",
    path: indexL2wOIS17NHMeta?.path ?? "/claim/:uuid()",
    meta: indexL2wOIS17NHMeta || {},
    alias: indexL2wOIS17NHMeta?.alias || [],
    redirect: indexL2wOIS17NHMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: failXdaHQUDRb0Meta?.name ?? "claim-fail",
    path: failXdaHQUDRb0Meta?.path ?? "/claim/fail",
    meta: failXdaHQUDRb0Meta || {},
    alias: failXdaHQUDRb0Meta?.alias || [],
    redirect: failXdaHQUDRb0Meta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/fail.vue").then(m => m.default || m)
  },
  {
    name: indexIPJFi2Je1FMeta?.name ?? "claim",
    path: indexIPJFi2Je1FMeta?.path ?? "/claim",
    meta: indexIPJFi2Je1FMeta || {},
    alias: indexIPJFi2Je1FMeta?.alias || [],
    redirect: indexIPJFi2Je1FMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/index.vue").then(m => m.default || m)
  },
  {
    name: successLtUxxRtTjZMeta?.name ?? "claim-success",
    path: successLtUxxRtTjZMeta?.path ?? "/claim/success",
    meta: successLtUxxRtTjZMeta || {},
    alias: successLtUxxRtTjZMeta?.alias || [],
    redirect: successLtUxxRtTjZMeta?.redirect || undefined,
    component: () => import("/workdir/pages/claim/success.vue").then(m => m.default || m)
  },
  {
    name: indexySkdaZZjB0Meta?.name ?? "contacts",
    path: indexySkdaZZjB0Meta?.path ?? "/contacts",
    meta: indexySkdaZZjB0Meta || {},
    alias: indexySkdaZZjB0Meta?.alias || [],
    redirect: indexySkdaZZjB0Meta?.redirect || undefined,
    component: () => import("/workdir/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexMKwREhkAPMMeta?.name ?? "contest",
    path: indexMKwREhkAPMMeta?.path ?? "/contest",
    meta: indexMKwREhkAPMMeta || {},
    alias: indexMKwREhkAPMMeta?.alias || [],
    redirect: indexMKwREhkAPMMeta?.redirect || undefined,
    component: () => import("/workdir/pages/contest/index.vue").then(m => m.default || m)
  },
  {
    name: indexNrjrPDWjinMeta?.name ?? "discounts",
    path: indexNrjrPDWjinMeta?.path ?? "/discounts",
    meta: indexNrjrPDWjinMeta || {},
    alias: indexNrjrPDWjinMeta?.alias || [],
    redirect: indexNrjrPDWjinMeta?.redirect || undefined,
    component: () => import("/workdir/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexYqXC3W8KKRMeta?.name ?? "faq",
    path: indexYqXC3W8KKRMeta?.path ?? "/faq",
    meta: indexYqXC3W8KKRMeta || {},
    alias: indexYqXC3W8KKRMeta?.alias || [],
    redirect: indexYqXC3W8KKRMeta?.redirect || undefined,
    component: () => import("/workdir/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexgrd9QoUdNTMeta?.name ?? "forget",
    path: indexgrd9QoUdNTMeta?.path ?? "/forget",
    meta: indexgrd9QoUdNTMeta || {},
    alias: indexgrd9QoUdNTMeta?.alias || [],
    redirect: indexgrd9QoUdNTMeta?.redirect || undefined,
    component: () => import("/workdir/pages/forget/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ1sPgYGdnvMeta?.name ?? "index",
    path: indexJ1sPgYGdnvMeta?.path ?? "/",
    meta: indexJ1sPgYGdnvMeta || {},
    alias: indexJ1sPgYGdnvMeta?.alias || [],
    redirect: indexJ1sPgYGdnvMeta?.redirect || undefined,
    component: () => import("/workdir/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdt8tCTppMnMeta?.name ?? "login",
    path: indexdt8tCTppMnMeta?.path ?? "/login",
    meta: indexdt8tCTppMnMeta || {},
    alias: indexdt8tCTppMnMeta?.alias || [],
    redirect: indexdt8tCTppMnMeta?.redirect || undefined,
    component: () => import("/workdir/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexNAcF2OimVeMeta?.name ?? "monitoring",
    path: indexNAcF2OimVeMeta?.path ?? "/monitoring",
    meta: indexNAcF2OimVeMeta || {},
    alias: indexNAcF2OimVeMeta?.alias || [],
    redirect: indexNAcF2OimVeMeta?.redirect || undefined,
    component: () => import("/workdir/pages/monitoring/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YwoilbelrwMeta?.name ?? "news-slug",
    path: _91slug_93YwoilbelrwMeta?.path ?? "/news/:slug()",
    meta: _91slug_93YwoilbelrwMeta || {},
    alias: _91slug_93YwoilbelrwMeta?.alias || [],
    redirect: _91slug_93YwoilbelrwMeta?.redirect || undefined,
    component: () => import("/workdir/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2Y6faI97hTMeta?.name ?? "news",
    path: index2Y6faI97hTMeta?.path ?? "/news",
    meta: index2Y6faI97hTMeta || {},
    alias: index2Y6faI97hTMeta?.alias || [],
    redirect: index2Y6faI97hTMeta?.redirect || undefined,
    component: () => import("/workdir/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexo45LdNCFk2Meta?.name ?? "promocode",
    path: indexo45LdNCFk2Meta?.path ?? "/promocode",
    meta: indexo45LdNCFk2Meta || {},
    alias: indexo45LdNCFk2Meta?.alias || [],
    redirect: indexo45LdNCFk2Meta?.redirect || undefined,
    component: () => import("/workdir/pages/promocode/index.vue").then(m => m.default || m)
  },
  {
    name: indexZdhdgNeEP1Meta?.name ?? "recover",
    path: indexZdhdgNeEP1Meta?.path ?? "/recover",
    meta: indexZdhdgNeEP1Meta || {},
    alias: indexZdhdgNeEP1Meta?.alias || [],
    redirect: indexZdhdgNeEP1Meta?.redirect || undefined,
    component: () => import("/workdir/pages/recover/index.vue").then(m => m.default || m)
  },
  {
    name: indexRkfcqitLlkMeta?.name ?? "register",
    path: indexRkfcqitLlkMeta?.path ?? "/register",
    meta: indexRkfcqitLlkMeta || {},
    alias: indexRkfcqitLlkMeta?.alias || [],
    redirect: indexRkfcqitLlkMeta?.redirect || undefined,
    component: () => import("/workdir/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexwEYHUqa81RMeta?.name ?? "reserves",
    path: indexwEYHUqa81RMeta?.path ?? "/reserves",
    meta: indexwEYHUqa81RMeta || {},
    alias: indexwEYHUqa81RMeta?.alias || [],
    redirect: indexwEYHUqa81RMeta?.redirect || undefined,
    component: () => import("/workdir/pages/reserves/index.vue").then(m => m.default || m)
  },
  {
    name: indexk3qcO1cw9bMeta?.name ?? "rules",
    path: indexk3qcO1cw9bMeta?.path ?? "/rules",
    meta: indexk3qcO1cw9bMeta || {},
    alias: indexk3qcO1cw9bMeta?.alias || [],
    redirect: indexk3qcO1cw9bMeta?.redirect || undefined,
    component: () => import("/workdir/pages/rules/index.vue").then(m => m.default || m)
  },
  {
    name: indexH6U53uCxdBMeta?.name ?? "shops",
    path: indexH6U53uCxdBMeta?.path ?? "/shops",
    meta: indexH6U53uCxdBMeta || {},
    alias: indexH6U53uCxdBMeta?.alias || [],
    redirect: indexH6U53uCxdBMeta?.redirect || undefined,
    component: () => import("/workdir/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: indexPDB9tVlXe9Meta?.name ?? "users",
    path: indexPDB9tVlXe9Meta?.path ?? "/users",
    meta: indexPDB9tVlXe9Meta || {},
    alias: indexPDB9tVlXe9Meta?.alias || [],
    redirect: indexPDB9tVlXe9Meta?.redirect || undefined,
    component: () => import("/workdir/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93FBaU04Y9qoMeta?.name ?? "verify-uuid",
    path: _91uuid_93FBaU04Y9qoMeta?.path ?? "/verify/:uuid()",
    meta: _91uuid_93FBaU04Y9qoMeta || {},
    alias: _91uuid_93FBaU04Y9qoMeta?.alias || [],
    redirect: _91uuid_93FBaU04Y9qoMeta?.redirect || undefined,
    component: () => import("/workdir/pages/verify/[uuid].vue").then(m => m.default || m)
  }
]