import { useAuthBasedFetch } from "~/api/useApi";

const URLS = {
  loginUrl: "/front-api/auth/login/",
  logoutUrl: "/front-api/auth/logout/",
  refreshUrl: "/front-api/auth/refresh/",
  fetchUserUrl: "/api/v1/users/me/",
};

export const useAuthApi = () => {
  const login = (data: any) => {
    return $fetch(URLS.loginUrl, { method: "POST", body: data });
  };

  const logout = () => {
    return $fetch(URLS.logoutUrl, { method: "POST" });
  };

  const refresh = () => {
    return useFetch<string>(URLS.refreshUrl);
  };

  const fetchUser = () => {
    return useAuthBasedFetch(URLS.fetchUserUrl);
  };

  return {
    login,
    logout,
    refresh,
    fetchUser,
  };
};
